<template>
  <div class="config-view h-100 p-10">
    <h3 class="b-b-1 m-b-10">研学活动</h3>
    <Button type="error" size="small" @click="$router.back()" class="extra"
      >返回</Button
    >
    <div class="activity-total-content" ref="cesium">
      <transition name="fade">
        <div
          :class="[
            'map_tool activity-total-info p-20',
            flag ? '' : 'left_close',
          ]"
        >
          <div class="detail_info">
            <div class="title f-18 f-bold c-white">{{ view.activityName }}</div>
            <div class="title f-16 m-t-20 c-white m-b-10">研学活动信息</div>
            <div class="sub-item m-t-10 m-b-5">
              所属机构： {{ view.organization && view.organization.orgName }}
            </div>
            <div class="sub-item m-t-10 m-b-5">
              参与人员：
              <Tag
                v-for="(item, index) in view.activityParticipants"
                :key="index"
                color="cyan"
                >{{ item.userName }}</Tag
              >
            </div>
            <div class="sub-item m-t-10 m-b-5">
              开始时间： {{ view.startTime }}
            </div>
            <div class="sub-item m-b-5">结束时间： {{ view.endTime }}</div>
            <div class="sub-item m-b-5">
              发起人： {{ view.owner && view.owner.userName }}
            </div>
            <div class="sub-item m-b-5 b-b-10">
              活动简介：
              {{
                view.activityDetail ? getSimpleText(view.activityDetail) : "--"
              }}
            </div>

            <div class="title f-16 m-t-20 c-white b-t-10 m-b-10">
              研学活动路线
            </div>
            <div class="sub-item c-gray m-b-5" v-if="view.researchLines == null">
              暂无研学活动路线
            </div>
            <div class="sub-item m-b-5" v-else>
              <Tag
                v-for="(item, index) in lines"
                :key="index"
                color="magenta"
                class="c-h"
                @click.native="lineGpx(item.lineGpx)"
                >{{ item.lineName }}</Tag
              >
            </div>

            <div class="title f-16 m-t-20 c-white b-t-10  m-b-10">
              研学活动点
            </div>
            
            <div class="sub-item c-gray m-b-5" v-if="view.researchPoints == null">
              暂无研学活动点
            </div>
            <div class="sub-item m-b-5" v-else>
              <Tag
                v-for="(item, index) in points"
                :key="index"
                color="blue"
                class="c-h"
                @click.native="getPanorama(item.point360)"
                >{{ item.pointName }}</Tag
              >
            </div>
            <div class="title f-16 m-t-20 c-white b-t-10 m-b-10">
              研学活动课程
            </div>
            <div class="sub-item c-gray m-b-5" v-if="view.researchCourse == null">
              暂无研学活动课程
            </div>
            <div class="sub-item m-b-5" v-else>
                <img
                  v-for="(item, index) in view.researchCourse"
                  :key="index"
                  src="/static/images/model/epub.jpg"
                  @click="openEPUB(item)"
                  alt=""
                  class="course-img m-r-5 c-h"
                />
            </div>
            <div class="title f-16 m-t-20 c-white b-t-10 m-b-10">研学资源</div>
            <div class="sub-item c-gray m-b-5">
              <van-tabs v-model="active" color="#2a8ffa" @click="onClick">
                <van-tab title="全部" name="all"></van-tab>
                <!-- 分享活动仅支持查看个人数据 -->
                <van-tab
                  :title="view.owner.userName"
                  :name="view.owner.userId"
                  v-if="view.owner"
                ></van-tab>
                <template
                  v-if="
                    view.activityParticipants &&
                    view.activityParticipants.length
                  "
                >
                  <van-tab
                    v-for="(item, index) in view.activityParticipants"
                    :title="item.userName"
                    :name="item.userId"
                    :key="index"
                  ></van-tab>
                </template>
              </van-tabs>
              <div class="c-gray m-t-10 m-b-10 p-l-20" v-if="!resource.length">
                暂无研学资源
              </div>
              <div class="c-gray m-t-10 p-l-20" v-else>
                <div class="m-t-10 f-bold c-white">轨迹</div>
                <div class="m-t-5" v-if="resourceType.gpxData.length == 0">暂无轨迹</div>
                <div class="m-t-5" v-else>
                  <Tag
                    v-for="(item, index) in resourceType.gpxData"
                    :key="index"
                    color="purple"
                    class="c-h"
                    @click.native="lineGpx(item.context)"
                    >{{ `航迹_${item.userId}` }}</Tag
                  >
                </div>
                
                <div class="m-t-10 f-bold c-white">图片</div>
                <div class="m-t-5" v-if="resourceType.imageData.length == 0">暂无图片</div>
                <div class="m-t-5 total_image" v-for="item in resourceType.imageData" :key="item.id" v-else>
                  <div class="total_img">
                    <van-image :src="item.context" lazy-load class="img_normal">
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                      <template v-slot:error>
                        <img src="@/assets/no_image.png" class="img_error" />
                      </template>
                    </van-image>
                  </div>
                </div>

                <div class="m-t-10 f-bold c-white">视频</div>
                <div class="m-t-5" v-if="resourceType.videoData.length == 0">暂无视频</div>
                <div class="m-t-5 total_video" v-for="item in resourceType.videoData" :key="item.id" v-else>
                  <div>
                    <video class="video" controls>
                      <source :src="item.context" />
                    </video>
                  </div>
                </div>

                <div class="m-t-10 f-bold c-white">文本</div>
                <div class="m-t-5" v-if="resourceType.textData.length == 0">暂无本文</div>
                <div class="m-t-5 total_text" v-for="(item, index) in resourceType.textData" :key="item.id" v-else>
                  {{ `${index + 1}、  ${item.context}` }}
                </div>

              </div>
            </div>

          </div>
          <div class="map_left_close">
            <Icon
              size="40"
              class="map_left_close_icon"
              :type="flag ? 'md-arrow-round-forward' : 'md-arrow-round-back'"
              @click="flag = !flag"
            />
          </div>
        </div>
      </transition>
    </div>
    
    <PanoramaView :modal="modal" />
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";
// 注册时可以配置额外的选项
Vue.use(Lazyload);
import { CesiumMap } from "@/libs";
import DrawTool from "@/libs/mapByCesium/DrawTool.js";
import { getDataById, getResourceAll } from "@/libs/api/activity";
import { getLineById } from "@/libs/api/trace";
import { getPointById } from "@/libs/api/point";
import { getPanoramaById } from '@/libs/api/panorama'
import { util } from "@/libs";
import PanoramaView from "@/views/dataV/index/panoramaView.vue";
export default {
  components: { PanoramaView },
  data() {
    return {
      getSimpleText: util.getSimpleText(),
      cesium: null,
      tool: null,
      timer: null,
      view: {},
      flag: true,
      lines: [],
      line: {},
      points: [],
      point: {},
      modal: {
        show: false,
        data: {}
      },
      resource: [],
      active: 'all',
    };
  },
  computed: {
    resourceType() {
      let imageData = [],
        videoData = [],
        textData = [],
        gpxData = [];
      if (this.resource.length) {
        imageData = this.resource.filter(
          (item) => item.resourceType == "pan/image"
        );
        videoData = this.resource.filter(
          (item) => item.resourceType == "pan/video"
        );
        textData = this.resource.filter(
          (item) => item.resourceType == "pan/text"
        );
        gpxData = this.resource.filter(
          (item) => item.resourceType == "pan/gpx"
        );
      }
      return {
        imageData,
        videoData,
        textData,
        gpxData,
      };
    },
  },
  methods: {
    // 加载研学路线的航迹
    lineGpx(lineGpx) {
      if (lineGpx) this.tool.gpxLayer(lineGpx);
    },

    // 获取全景图
    async getPanorama(id) {
      if(id) {
        let { data } = await getPanoramaById({ id })
        if(data) {
          Object.assign(this.modal, { show: true, data })
        } else {
          this.$toast("暂无全景图！")
        }
      } else {
        this.$toast("暂无全景图！")
      }
    },

    // 获取研学线
    getLine(lineId) {
      getLineById({ id: lineId }).then((res) => {
        if (res.code == "HA0200") {
          this.line = {
            ...res.data,
            id: res.data.researchLineId,
            name: res.data.lineName,
            lng: res.data.lineCenter.lon,
            lat: res.data.lineCenter.lat,
            ele: 1000,
            width: 30,
            height: 30,
            image: "line.svg",
          };
          this.lines.unshift(this.line);
          const { lng, lat, ele } = this.line;
          if(this.cesium ) {
            this.addPoint(this.line);
            this.pointCamera(lng, lat, 1000);
          }
          //   添加gpx文件
          if (this.line.lineGpx !== "" || this.line.lineGpx != null) {
            if(this.cesium ) this.loadGPX(this.line.lineGpx);
          }
        }
      });
    },

    // 获取研学点
    getPoint(pointId) {
      getPointById({ id: pointId }).then(({ code, data }) => {
        if (code == "HA0200") {
          this.point = Object.assign(
            {},
            {
              ...data,
              id: data.researchPointId,
              name: data.pointName,
              lng: data.pointCoord.lon,
              lat: data.pointCoord.lat,
              ele: data.pointCoord.ele,
              width: 30,
              height: 30,
              image: "leida.svg",
            }
          );
          this.points.unshift(this.point);
          const { lng, lat, ele } = this.point;
          if(this.cesium ) this.addPoint(this.point);
          // this.pointCamera(lng, lat, 1000);
        }
      });
    },

    // 获取研学资源
    getResource() {
      let params = {};
      if (this.active != "all") {
        params = { user_id: this.active };
      }
      getResourceAll({ activity_id: this.$route.query.id, ...params }).then(res => {
        this.resource = res.data
      })
    },
    
    // 资源按用户查询 
    onClick() {
      this.getResource()

    },
  },

  async mounted() {
    // 加载cesium
    const cesium = await new CesiumMap(this.$refs.cesium, {
      homeButton: false,
      sceneModePicker: false,
      isCredit: true,
      isAnimation: true,
      isTimeline: true,
    });
    this.cesium = cesium;
    this.tool = await new DrawTool(cesium);

    const { id } = this.$route.query;
    if (id) {
      const { code, data, msg } = await getDataById({ activity_id: id });
      await this.getResource()
      if (code == "HA0200") {
        this.view = data;
        if (data.researchLines) {
          data.researchLines.map((item) => this.getLine(item));
        }
        if (data.researchPoints) {
          data.researchPoints.map((item) => this.getPoint(item));
        }
      } else {
        this.$Message.error(msg);
      }
    }
  },
  beforeDestroy() {
    this.cesium.removeCesium();
    // this.timer && clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.config-view {
  position: relative;
  .extra {
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .activity-total-content {
    height: calc(100% - 40px);
    position: relative;
    overflow: hidden;
    background: #333;
    .activity-total-info {
      z-index: 1;
      position: absolute;
      bottom: 20px;
      color: #fff;
      right: 0;
      height: calc(100% - 40px);
      background: rgba(0, 0, 0, 0.4);
      box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
      width: 400px;
      transition: all 3s;
      // border: solid 1px rgb(38, 170, 228);
      border-radius: 20px 0 0 20px;
      .detail_info {
        height: 100%;
        overflow-y: scroll;
        .b-b-10 {
          padding-bottom: 10px;
          border-bottom: 10px solid rgba(0, 0, 0, 0.2);
        }
        .b-t-10 {
          padding-top: 10px;
          border-top: 10px solid rgba(0, 0, 0, 0.2);
        }
        .course-img {
          display: inline-block;
          width: 60px;
          height: 80px;
        }
      }
      .total_image {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
        height: 100%;
        padding: 10px;
        padding-left: 20px;
        padding-right:20px ;
        .img_normal {
          display: flex;
          align-items: center;
          min-height: 200px;
          background: rgba(0, 0, 0, 0.2);
        }
      }
      .total_video {
        margin-top: 4px;
        div {
          width: 100%;
          height: 220px;
          margin: 8px 0;
          video {
            width: 100%;
            height: 100%;
          }
        }
      }
      .total_text {
        text-indent: 2em;
      }
    }
    .map_left_close {
      position: absolute;
      top: calc(50% - 60px);
      right: 400px;
      width: 60px;
      height: 60px;
      border: solid 1px #fff;
      border-radius: 50%;
      text-align: center;
      line-height: 70px;
      z-index: 999;
    }
    .map_left_close_icon:hover {
      color: #efefef;
    }
    .left_close {
      right: -400px;
    }
  }
}
</style>

<style lang="less">
.activity-total-content {
  .van-tabs__nav {
    background-color: rgba(33, 37, 39, 0.6);
  }
  .van-tab {
    color: #efefef;
  }
  .van-tab--active {
    color: #fff
  }
  .van-image__error, .van-image__loading {
    background-color: rgba(33, 37, 39, 0.6);
  }
}
</style>